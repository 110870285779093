exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-services-behaviour-therapist-tsx": () => import("./../../../src/pages/services/behaviour-therapist.tsx" /* webpackChunkName: "component---src-pages-services-behaviour-therapist-tsx" */),
  "component---src-pages-services-counsellor-tsx": () => import("./../../../src/pages/services/counsellor.tsx" /* webpackChunkName: "component---src-pages-services-counsellor-tsx" */),
  "component---src-pages-services-dietician-tsx": () => import("./../../../src/pages/services/dietician.tsx" /* webpackChunkName: "component---src-pages-services-dietician-tsx" */),
  "component---src-pages-services-occupational-therapy-tsx": () => import("./../../../src/pages/services/occupational-therapy.tsx" /* webpackChunkName: "component---src-pages-services-occupational-therapy-tsx" */),
  "component---src-pages-services-physiotherapist-tsx": () => import("./../../../src/pages/services/physiotherapist.tsx" /* webpackChunkName: "component---src-pages-services-physiotherapist-tsx" */),
  "component---src-pages-services-psychology-tsx": () => import("./../../../src/pages/services/psychology.tsx" /* webpackChunkName: "component---src-pages-services-psychology-tsx" */),
  "component---src-pages-services-speech-therapy-tsx": () => import("./../../../src/pages/services/speech-therapy.tsx" /* webpackChunkName: "component---src-pages-services-speech-therapy-tsx" */)
}

